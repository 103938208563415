import {orderBy} from 'lodash';

import {recursiveResponsesFetch} from '../../../lib/samples';
import {
  createSample,
  updateSample,
  deleteSample,
  fetchResponsesLength,
} from '../../../lib/firestore/samples';

export default async function doSample() {
  // 1- Open modal in order to display process progress
  await this.setState({isModalOpen: true});

  // 2- Create Sample
  const {id: sampleId} = await createSample(this.props.uid);

  // 3- Fetch responses from graphQl API
  const {errors, responses} = await fetchResponses(
    this.state,
    this.props.settingId,
    sampleId,
    this.handleProgress
  );

  if (responses.length > 0 && errors.length === 0) {
    // 4- Update the sample with dataset info
    const sortedResponses = orderBy(responses, 'responseDate');
    const size = await fetchResponsesLength(sampleId);

    const dataset = {
      size,
      start: sortedResponses[0].responseDate,
      end: sortedResponses[sortedResponses.length - 1].responseDate,
      intent: this.state.formState.intent || 'all',
    };
    await updateSample(sampleId, {
      requestedSize: Number(this.state.formState.size),
      dataset,
    });
    // 5- Update samples in redux state
    this.props.fetchSamples();

    // 6- Redirect to newly created sample
    await this.setState({
      isModalOpen: false,
    });
    this.props.history.push({
      pathname: `/analysis/samples/list/${sampleId}`,
      search: window.location.search,
    });
  } else {
    // Delete the failed sample
    await deleteSample(sampleId);

    // update the state & close the modal in order to display the form again
    await this.setState({
      errors,
      isModalOpen: false,
      resultsLength: responses.length,
    });
  }
}

async function fetchResponses(state, settingsId, sampleId, handleProgress) {
  const {startDate, intent, size} = state.formState;
  const graphQlVars = {
    intent,
    respondedAfter: startDate,
    settingsId,
  };

  const {errors, responses} = await recursiveResponsesFetch(
    graphQlVars,
    {limit: size},
    sampleId,
    handleProgress
  );

  return {errors, responses};
}

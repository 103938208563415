import firebase from 'firebase/app';

export const getProjectId = () => firebase.app().options.projectId;

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

  firebase.auth().signInWithPopup(provider);
};

import {
  fetchAllSampleResponses,
  deleteSampleResults,
  addAnalysisResults,
  updateSample,
} from '../../../lib/firestore/samples';

export default async function doAnalysis() {
  console.time();
  const confirmation = window.confirm(
    'Are you sure to start a new analysis? It could take several minutes.'
  );
  if (!confirmation) {
    return;
  }
  // 1- open progress modal
  await this.setState({isModalOpen: true});

  // 2- getJwtToken to ensurte jwt is fetched
  await this.setState({progress: 0.05});
  await this.props.getJwt();
  await this.setState({progress: 0.1});

  // 3- Reset Sample analysis
  const {
    sample: {id: sampleId},
  } = this.props;
  await deleteSampleResults(sampleId);
  await this.setState({progress: 0.2});

  // 4- get all sample responses
  const allSamplesResponses = await fetchAllSampleResponses(sampleId);
  await this.setState({progress: 0.3});

  // 5 analyse every response
  let progress = 0.3;
  const step = 60 / allSamplesResponses.length / 100;
  const analysisResults = await Promise.all(
    allSamplesResponses.map(async ({str}) => {
      const res = await this.props.analyseResponse(str);
      progress += step;
      await this.setState({progress});
      return res;
    })
  );

  // 6- store every result in analysis object in db
  await addAnalysisResults(analysisResults, sampleId);
  await this.setState({progress: 0.92});

  // 7- add analysisCreation
  await updateSample(sampleId, {
    analysisCreation: new Date().toISOString(),
  });
  await this.setState({progress: 0.95});

  // 8- Update samples in redux state
  this.props.fetchSamples();
  await this.setState({progress: 0.97});

  // 9- refresh result table
  this.getSampleResults();

  // 10- close modal
  await this.setState({isModalOpen: false});
  console.timeEnd();
}

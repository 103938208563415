import React from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid';

import {SAMPLE_SCHEMA} from '../../../selectors/samples';
import ListItem from './list-item';

export default function SamplesList({samples}) {
  return (
    <table className="table mb-4 light-table">
      <thead>
        <tr>
          <th>Creation</th>
          <th>Dataset size</th>
          <th>Dataset timeframe</th>
          <th>Intent</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!samples && (
          <tr>
            <td colSpan="100%">Loading...</td>
          </tr>
        )}
        {samples &&
          samples.length === 0 && (
            <tr>
              <td colSpan="100%">No sample, yet.</td>
            </tr>
          )}
        {samples &&
          samples.map(sample => <ListItem key={uuidv1()} sample={sample} />)}
      </tbody>
    </table>
  );
}

SamplesList.propTypes = {
  samples: PropTypes.arrayOf(SAMPLE_SCHEMA),
};

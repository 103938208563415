import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Power, AlertOctagon, Award, Database, User} from 'react-feather';
import {Navbar, Nav, NavItem} from 'reactstrap';
import styled from 'styled-components';
import {get} from 'lodash';

import {refreshParams} from '../../../actions/app';
import {getProjectId} from '../../../lib/firebase';

import Admin from '../admin';

class Debugger extends Component {
  constructor(props) {
    super();
    this.state = {
      projectId: getProjectId().split('-')[1],
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleProjectId = this.handleProjectId.bind(this);
  }

  handleToggle(key, value) {
    localStorage.setItem(key, value);
    this.props.refreshParams();
  }

  handleProjectId() {
    const newProject =
      this.state.projectId === 'staging' ? 'production' : 'staging';
    const reload = window.confirm(
      `Are you sure wanted to switch to ${newProject}? The page will be reloaded.`
    );
    if (reload) {
      localStorage.setItem('production', newProject === 'production');
      localStorage.setItem('staging', newProject === 'staging');
      window.location.reload();
    }
  }

  render() {
    const {
      debug,
      isAdmin,
      offline,
      loggedUserEmail,
      user,
      settingId,
    } = this.props;
    const projectId = getProjectId().split('-')[1];
    const settingUserEmail = get(user, 'uiSettings.email', 'email unknown');
    const isLoggedWithOtherAccount = settingUserEmail !== loggedUserEmail;
    return (
      <Admin>
        <Navbar
          className="inner-navbar justify-content-end"
          style={{left: 'auto'}}
          fixed="bottom"
          color="white"
          expand="sm"
          light
        >
          <Nav>
            {isLoggedWithOtherAccount && (
              <StyledNavItem className="text-primary">
                <User size="15" />{' '}
                <strong className="mr-1">{settingUserEmail}</strong>-
                <small className="ml-1">{settingId}</small>
              </StyledNavItem>
            )}

            <StyledNavItem
              className={`clic text-${
                projectId === 'production' ? 'warning' : 'dark'
              }`}
              onClick={this.handleProjectId}
            >
              <Database size="15" /> {projectId}
            </StyledNavItem>

            <StyledNavItem
              className={`clic text-${debug ? 'success' : 'danger'}`}
              onClick={() => this.handleToggle('debug', !debug)}
            >
              <AlertOctagon size="15" /> Debug {debug ? 'on' : 'off'}
            </StyledNavItem>

            <StyledNavItem className={`text-${offline ? 'danger' : 'success'}`}>
              <Power size="15" /> {offline ? 'Offline' : 'Online'}
            </StyledNavItem>

            {isAdmin && (
              <StyledNavItem className="text-success">
                <Award size="15" /> Admin
              </StyledNavItem>
            )}
          </Nav>
        </Navbar>
      </Admin>
    );
  }
}

export default connect(
  (state, props) => ({
    debug: state.app.debug,
    isAdmin: state.app.isAdmin,
    offline: state.app.offline,
    loggedUserEmail: state.app.loggedUser.email,
    user: state.firestore.user,
    settingId: get(state, 'firestore.setting.id'),
  }),
  dispatch => ({
    ...bindActionCreators(
      {
        refreshParams,
      },
      dispatch
    ),
  })
)(Debugger);

Debugger.propTypes = {
  debug: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  offline: PropTypes.bool.isRequired,
  loggedUserEmail: PropTypes.string,
  refreshParams: PropTypes.func.isRequired,
  user: PropTypes.object,
  settingId: PropTypes.string,
};

const StyledNavItem = styled(NavItem).attrs({
  className: 'd-flex align-items-center small ml-2',
})`
  svg {
    margin-right: 5px;
  }
`;

// <NavItem className="small ml-2">
// <strong>Project:</strong>{' '}
// <a
//   target="_blank"
//   href={`https://console.firebase.google.com/project/${projectId}/authentication/users`}
// >
//   {projectId}
// </a>
// </NavItem>

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Row, Col} from 'reactstrap';

import Debugger from '../components/architecture/debugger';

export default function Error({error}) {
  return (
    <CenteredRow>
      <Col>
        <h4>An error occurred</h4>
        <code>{error}</code>
      </Col>
      <Debugger />
    </CenteredRow>
  );
}

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

const CenteredRow = styled(Row).attrs({
  className: 'align-items-center text-center m-0',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

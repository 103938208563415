import React from 'react';
import {Redirect} from 'react-router-dom';

import App from './app';

import Home from './views/home';
import AI from './views/ai';
import AIModel from './views/ai/model';
import AIIntents from './views/ai/intents';

import Intents from './views/intents';
import IntentsList from './views/intents/list';
import IntentsDetails from './views/intents/details';

import Analysis from './views/analysis';
import AnalysisSamplesNew from './views/analysis/samples/new';
import AnalysisSamplesList from './views/analysis/samples/list';
import AnalysisSamplesView from './views/analysis/samples/view';

export default [
  {
    component: App,
    routes: [
      {
        path: '/home',
        exact: true,
        component: Home,
      },
      {
        path: '/ai',
        component: AI,
        routes: [
          {
            path: '/ai/model',
            exact: true,
            component: AIModel,
          },
          {
            path: '/ai/intents',
            exact: true,
            component: AIIntents,
          },
        ],
      },
      {
        path: '/intents',
        component: Intents,
        routes: [
          {
            path: '/intents/list',
            exact: true,
            component: IntentsList,
          },
          {
            path: '/intents/:id',
            exact: true,
            component: IntentsDetails,
          },
        ],
      },
      {
        path: '/analysis',
        component: Analysis,
        routes: [
          {
            path: '/analysis/samples/new',
            exact: true,
            component: AnalysisSamplesNew,
          },
          {
            path: '/analysis/samples/list',
            exact: true,
            component: AnalysisSamplesList,
          },
          {
            path: '/analysis/samples/list/:id',
            exact: true,
            component: AnalysisSamplesView,
          },
        ],
      },
      {
        path: '*',
        component: () => (
          <Redirect
            to={{
              pathname: '/home',
              search: window.location.search,
            }}
          />
        ),
      },
    ],
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Switch} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';

export default function Analysis({route}) {
  return (
    <div>
      {renderRoutes(route.routes)}

      <Switch>
        <Redirect
          exact
          from="/analysis"
          to={{
            pathname: 'analysis/samples/list',
            search: window.location.search,
          }}
        />
      </Switch>
    </div>
  );
}

Analysis.propTypes = {
  route: PropTypes.object.isRequired,
};

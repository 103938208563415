import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

export default function CommonNavLink({children, pathname, exact}) {
  return (
    <StyledNavLink
      to={{pathname, search: window.location.search}}
      exact={exact}
      className="nav-link"
      activeClassName="active"
    >
      {children}
    </StyledNavLink>
  );
}

CommonNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

const StyledNavLink = styled(NavLink)`
  border-bottom: 2px solid var(--white);
  color: var(--gray);
  margin: 0 10px;
  padding: 20px 7px;

  &:hover {
    color: var(--gray-dark);
  }
  &.active {
    color: var(--hubware) !important;
    border-bottom: 2px solid var(--hubware);
  }

  svg {
    margin-right: 0.7rem;
  }
`;

import firebase from 'firebase/app';
import queryString from 'query-string';

import {getUser} from '../lib/firestore';
import {connectFirestore} from './firestore';

export const actions = {
  RESET_STORE: 'RESET_STORE',
  SET_LOGGED_USER: 'SET_LOGGED_USER',
  SET_IS_ADMIN: 'SET_IS_ADMIN',
  SET_ERROR: 'SET_ERROR',
  REFRESH_PARAMS: 'REFRESH_PARAMS',
};

export default actions;

export const setLoggedUser = user => ({
  type: actions.SET_LOGGED_USER,
  user,
});

export const setAuth = history => (dispatch, getState) => {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      if (history.location.pathname === '/sign-in') {
        history.push({pathname: '/', search: window.location.search});
      }
      // Set logged user datas
      dispatch(setLoggedUser(user));
      // Check if he's admin
      getUser(user.uid).then(({admin = false}) => {
        dispatch(setAdmin(admin));
      });
      // Connect firestore Data

      dispatch(connectFirestore(queryString.parse(window.location.search).uid));
    } else {
      dispatch(resetStore());
      history.push({pathname: '/sign-in', search: window.location.search});
    }
  });
};

export const resetStore = stores => ({
  type: actions.RESET_STORE,
  stores,
});

export const setAdmin = isAdmin => ({
  type: actions.SET_IS_ADMIN,
  isAdmin,
});

export const setError = error => ({
  type: actions.SET_ERROR,
  error,
});

export const refreshParams = error => ({
  type: actions.REFRESH_PARAMS,
});

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {bindActionCreators} from 'redux';
import {Prompt, Link} from 'react-router-dom';
import {get, capitalize} from 'lodash';
import {MoreHorizontal} from 'react-feather';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import {SAMPLE_SCHEMA} from '../../../selectors/samples';
import {fetchSamples, deleteSample} from '../../../lib/firestore/samples';
import {localeDate} from '../../../lib/utils';

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingDeletion: false,
    };

    this.handleDeletion = this.handleDeletion.bind(this);
  }

  async handleDeletion() {
    this.setState({pendingDeletion: true});
    await deleteSample(this.props.sample.id);
    this.props.fetchSamples();
  }

  render() {
    const {sample} = this.props;
    const {pendingDeletion} = this.state;

    if (pendingDeletion) {
      return (
        <tr>
          <Prompt
            when={pendingDeletion}
            message={() =>
              `Sample deletion in progress. Are you sure to want to close this window?`
            }
          />
          <td colSpan="100%" className="text-muted">
            Pending deletion...
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <td>{sample.creation}</td>
        <td>
          {get(sample, 'dataset.size')} message
          {get(sample, 'dataset.size') > 1 && 's'}
          {get(sample, 'dataset.size') !== sample.requestedSize &&
            sample.requestedSize && (
              <small>
                {' '}
                /{sample.requestedSize}
                &nbsp;requested&nbsp;messages
              </small>
            )}
        </td>
        <td>
          From {localeDate(get(sample, 'dataset.start'))} to{' '}
          {localeDate(get(sample, 'dataset.end'))}
        </td>
        <td>{capitalize(get(sample, 'dataset.intent', 'All'))}</td>
        <td>
          <UncontrolledDropdown>
            <StyledDropdown color="link">
              <MoreHorizontal className="text-dark" size="20" />
            </StyledDropdown>
            <DropdownMenu>
              <StyledDropdownItem>
                <Link
                  className="text-dark"
                  to={{
                    pathname: `list/${sample.id}`,
                    search: window.location.search,
                  }}
                >
                  Open
                </Link>
              </StyledDropdownItem>
              <StyledDropdownItem
                className="text-dark"
                onClick={this.handleDeletion}
              >
                Delete
              </StyledDropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
    );
  }
}

const StyledDropdown = styled(DropdownToggle)`
  border-width: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
`;
const StyledDropdownItem = styled(DropdownItem).attrs({
  className: 'clic',
})`
  &:active {
    background: inherit;
  }
  &:focus {
    outline-width: 0;
  }
  &:hover > a {
    text-decoration: none;
  }
`;

export default connect(
  (state, props) => ({}),
  dispatch => ({
    ...bindActionCreators(
      {
        fetchSamples,
      },
      dispatch
    ),
  })
)(ListItem);

ListItem.propTypes = {
  sample: SAMPLE_SCHEMA,
  fetchSamples: PropTypes.func.isRequired,
};

import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';

import {
  getFormatedEvalJobsComparison,
  EVAL_JOBS_SCHEMA,
} from '../../selectors/eval-jobs';
import CValue from '../compared-value';

const Models = ({evalJobs}) => {
  const {current, previous} = evalJobs;
  console.error('AJOUTER LES RELATIFS !!');
  return (
    <table className="table mb-4 light-table">
      <thead>
        <tr>
          <th>Creation</th>
          <th>Version</th>
          <th>Precision</th>
          <th>Coverage</th>
          <th>Training dataset</th>
          <th>Evaluation dataset</th>
        </tr>
      </thead>
      <tbody>
        {current ? (
          <tr>
            <td>{current.creation}</td>
            <td>
              <pre className="m-0">{current.version}</pre>
            </td>
            <td>
              <CValue
                value={current.precision}
                prevValue={get(previous, 'precision')}
              >
                %
              </CValue>
            </td>

            <td>
              <CValue
                value={current.coverage}
                prevValue={get(previous, 'coverage')}
              >
                %
              </CValue>
            </td>
            <td>
              <CValue
                value={current.datasetSize}
                prevValue={get(previous, 'datasetSize')}
              >
                {' '}
                message
                {current.datasetSize > 1 && 's'}
              </CValue>
            </td>
            <td>
              <CValue
                value={current.evalsetSize}
                prevValue={get(previous, 'evalsetSize')}
              >
                {' '}
                message
                {current.evalsetSize > 1 && 's'}
              </CValue>
            </td>
          </tr>
        ) : (
          <tr>
            <td colSpan="100%">No models deployed</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default connect(state => ({
  evalJobs: getFormatedEvalJobsComparison(state),
}))(Models);

Models.propTypes = {
  evalJobs: EVAL_JOBS_SCHEMA,
};

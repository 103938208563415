import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, ModalHeader, ModalBody, Row, Col} from 'reactstrap';
import {get} from 'lodash';
import uuidv1 from 'uuid';
import styled from 'styled-components';

import {fetchResponseById} from '../../../../../lib/firestore/samples';

class ResponseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      response: undefined,
      responseId: undefined,
    };

    this.toggle = this.toggle.bind(this);
  }

  async toggle() {
    const {modal} = this.state;
    if (modal) {
      this.setState({
        modal: !this.state.modal,
      });
    } else {
      await this.setState({
        modal: !this.state.modal,
        responseId: this.props.result.response.id,
        loading: true,
      });
      console.log('fetch response', this.state.responseId);
      const response = await fetchResponseById(
        this.props.sampleId,
        this.state.responseId
      );
      console.log(`---------- ${response.id} ----------`);
      console.log('response', JSON.parse(response.str));
      console.log('result', this.props.result);
      this.setState({
        response,
        loading: false,
      });
    }
  }

  render() {
    const {result} = this.props;
    const {loading, response, responseId} = this.state;

    return (
      <div>
        <Button color="link" onClick={this.toggle}>
          Details
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>
            Response #{responseId && responseId.split('-')[1]}
          </ModalHeader>
          <ModalBody>
            {loading && (
              <div className="text-muted text-center">Loading...</div>
            )}

            {!loading && (
              <div>
                <Display result={result} response={response} />
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ResponseModal;

ResponseModal.propTypes = {
  result: PropTypes.object.isRequired,
  sampleId: PropTypes.string.isRequired,
};

const Display = ({result, response: rawResponse}) => {
  const response = JSON.parse(rawResponse.str);
  return (
    <div key={uuidv1()}>
      <h5>Infos</h5>
      <Row>
        <Col>
          <p>
            <strong>Requester</strong>:{' '}
            {[
              get(response, 'zendeskTicket.requester.name'),
              get(response, 'zendeskTicket.requester.email'),
            ].join(' - ')}
          </p>
        </Col>
      </Row>
      <h5>Conversation</h5>
      {get(response, 'zendeskTicket.comments', [])
        .reverse()
        .map(comment => (
          <CommentsWrapper key={uuidv1()}>
            {comment.body.replace(/\\/g, '')}
          </CommentsWrapper>
        ))}
      <hr />
      <h5>Response</h5>
      see console
      <h5>Macro filter</h5>
      {result.filtersResults.map(filter => (
        <Fragment key={uuidv1()}>
          <dl>
            <dt className="col-sm-3">Suggested</dt>
            <dd className="col-sm-9">{filter.suggest.toString()}</dd>
            <dt className="col-sm-3">Reason</dt>
            <dd className="col-sm-9">{filter.reason}</dd>
            <dt className="col-sm-3">Logs</dt>
            <dd className="col-sm-9">
              {filter.log.map(log => (
                <code className="d-block" key={uuidv1()}>
                  {log}
                </code>
              ))}
            </dd>
          </dl>
          <hr />
        </Fragment>
      ))}
    </div>
  );
};

Display.propTypes = {
  result: PropTypes.object.isRequired,
  response: PropTypes.object,
};

const CommentsWrapper = styled.div.attrs({
  className: 'pl-2 mb-3',
})`
  border-left: 3px solid var(--gray-300);
`;

import {get} from 'lodash';

import {addSampleResponse} from './firestore/samples';
import {graphql} from './callable-functions';

export async function recursiveResponsesFetch(
  variables,
  {limit = 1000, count = 0, after = null},
  sampleId,
  progressCb
) {
  const res = await requestCF(variables, after).then(
    res => res,
    (
      res // if error retry
    ) =>
      requestCF(variables, after).then(
        res => res,
        res => {
          console.error(res);
          return res;
        }
      )
  );
  const errors = parseErrors(res);
  const responses = parseResponses(res);
  const {endCursor, hasNextPage} = parsePageInfos(res);

  progressCb((count + responses.length / 2) / limit);

  await addSampleResponse(
    sampleId,
    responses.filter(r => byteCount(JSON.stringify(r)) < 900000) // Avoid object too heavy
  ).then(undefined, res => {
    console.error(res);
    return res;
  });

  progressCb((count + responses.length) / limit);

  if (/*hasNextPage && */ count + responses.length < limit) {
    const recursiveRes = await recursiveResponsesFetch(
      variables,
      {limit, count: count + responses.length, after: endCursor},
      sampleId,
      progressCb
    );
    return {
      errors: [...errors, ...recursiveRes.errors],
      responses: [...responses, ...recursiveRes.responses],
    };
  }

  return {errors, responses};
}

function parseErrors(res) {
  return get(res, 'data.errors', []);
}

function parseResponses(res) {
  return get(
    res,
    'data.data.settings.ticketConnectionByResponded.edges',
    []
  ).map(edge => get(edge, 'node', {}));
}

function parsePageInfos(res) {
  return get(
    res,
    'data.data.settings.ticketConnectionByResponded.pageInfo',
    {}
  );
}

function byteCount(s) {
  return encodeURI(s).split(/%..|./).length - 1;
}

const requestCF = (variables, after) =>
  graphql('SearchTicketsByResponse', {
    ...variables,
    after,
    first: 25,
  });

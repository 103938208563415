import React from 'react';
import Card from '../components/card';

export default () => (
  <div className="container">
    <Card>
      <div className="card-body">
        <h5 className="card-title">Home card</h5>
        <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
        <p className="card-text">
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </p>
      </div>
    </Card>
  </div>
);

import firebase from 'firebase/app';
import {chunk} from 'lodash';

import {setSamples} from '../../actions/firestore';

const CHUNKS_LENGTH = 50;

/* --------------- */
/* Samples         */
/* --------------- */
export const fetchSamples = () => async (dispatch, getState) => {
  const samples = await firebase
    .firestore()
    .collection('samples')
    .where('owners', 'array-contains', getState().firestore.user.id)
    .get()
    .then(querySnapshot => {
      const res = [];
      querySnapshot.forEach(doc => res.push({id: doc.id, ...doc.data()}));
      return res;
    });

  return dispatch(setSamples(samples));
};

export const createSample = uid =>
  firebase
    .firestore()
    .collection('samples')
    .add({
      owners: [uid],
      creation: new Date().toISOString(),
    });

export const deleteSample = async sampleId => {
  await Promise.all([
    deleteSampleResponses(sampleId),
    deleteSampleResults(sampleId),
  ]);
  return firebase
    .firestore()
    .collection('samples')
    .doc(sampleId)
    .delete();
};

export const deleteSampleSubCollection = async (sampleId, subcollection) => {
  const db = firebase.firestore();
  await db
    .collection(`samples/${sampleId}/${subcollection}`)
    .get()
    .then(querySnapshot => {
      const refs = querySnapshot.docs.map(({ref}) => ref);
      const chunks = chunk(refs, CHUNKS_LENGTH);

      const commits = chunks.map(ch => {
        const batch = db.batch();
        ch.forEach(ref => batch.delete(ref));
        return batch.commit();
      });

      return Promise.all(commits);
    });
};

export const updateSample = (sampleId, payload) =>
  firebase
    .firestore()
    .collection('samples')
    .doc(sampleId)
    .update(payload);

/* --------------- */
/* Responses       */
/* --------------- */
export const deleteSampleResponses = sampleId =>
  deleteSampleSubCollection(sampleId, 'responses');

export const addSampleResponse = (sampleId, responses) => {
  const db = firebase.firestore();
  const batch = db.batch();
  responses.forEach(response =>
    batch.set(db.collection(`samples/${sampleId}/responses`).doc(), {
      id: response.id,
      str: JSON.stringify(response),
    })
  );
  return batch.commit();
};

export const fetchResponsesLength = sampleId =>
  firebase
    .firestore()
    .collection(`samples/${sampleId}/responses`)
    .get()
    .then(({size}) => size);

export const fetchAllSampleResponses = sampleId =>
  firebase
    .firestore()
    .collection(`samples/${sampleId}/responses`)
    .get()
    .then(querySnapshot => {
      const res = [];
      querySnapshot.forEach(doc => res.push(doc.data()));
      return res;
    });

export const fetchResponseById = (sampleId, responseId) => {
  return firebase
    .firestore()
    .collection(`samples/${sampleId}/responses`)
    .where('id', '==', responseId)
    .get()
    .then(querySnapshot => {
      const res = [];
      querySnapshot.forEach(doc => res.push(doc.data()));
      if (res.length > 1) {
        console.error('query result longer than 1 item');
      }
      return res[0];
    });
};

/* --------------- */
/* Results         */
/* --------------- */

export const deleteSampleResults = sampleId =>
  deleteSampleSubCollection(sampleId, 'results');

export const addAnalysisResults = (results, sampleId) => {
  const db = firebase.firestore();
  const chunks = chunk(results, CHUNKS_LENGTH);

  const commits = chunks.map(ch => {
    const batch = db.batch();
    ch.forEach(result =>
      batch.set(db.collection(`samples/${sampleId}/results`).doc(), result)
    );

    return batch.commit();
  });

  return Promise.all(commits);
};

export const fetchAllSampleResults = sampleId => {
  return firebase
    .firestore()
    .collection(`samples/${sampleId}/results`)
    .get()
    .then(querySnapshot => {
      const res = [];
      querySnapshot.forEach(doc => res.push(doc.data()));
      return res;
    });
};

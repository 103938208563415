import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import {
  MACROS_TITLES_SCHEMA,
  getMacrosTitles,
} from '../../../../../selectors/macros';
import {getMacroTitle} from '../../../../../lib/macros';

const SuggestedMacro = ({macros, macrosTitles, zendeskDomain}) => {
  if (!macros) {
    return null;
  }

  return (
    <div>
      {macros.map(id => (
        <a
          key={id}
          target="_blank"
          rel="noopener noreferrer"
          title={getMacroTitle(macrosTitles, id)}
          href={`https://${zendeskDomain}.zendesk.com/agent/admin/macros/${id}`}
        >
          {id}{' '}
        </a>
      ))}
    </div>
  );
};

export default connect((state, props) => ({
  macrosTitles: getMacrosTitles(state),
  zendeskDomain: get(state, 'firestore.setting.zendesk.domain'),
}))(SuggestedMacro);

SuggestedMacro.propTypes = {
  macros: PropTypes.array,
  macrosTitles: MACROS_TITLES_SCHEMA,
  zendeskDomain: PropTypes.string.isRequired,
};

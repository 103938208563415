import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import {
  MACROS_TITLES_SCHEMA,
  getMacrosTitles,
} from '../../../../../selectors/macros';
import {getMacroTitle} from '../../../../../lib/macros';

const MacroUsed = ({id, macrosTitles, zendeskDomain}) => {
  if (!id) {
    return null;
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      title={getMacroTitle(macrosTitles, id)}
      href={`https://${zendeskDomain}.zendesk.com/agent/admin/macros/${id}`}
    >
      {id}
    </a>
  );
};

export default connect((state, props) => ({
  macrosTitles: getMacrosTitles(state),
  zendeskDomain: get(state, 'firestore.setting.zendesk.domain'),
}))(MacroUsed);

MacroUsed.propTypes = {
  id: PropTypes.string,
  macrosTitles: MACROS_TITLES_SCHEMA,
  zendeskDomain: PropTypes.string.isRequired,
};

import {getJwt} from '../actions/data';
import {getProjectId} from './firebase';

export const runFilters = ({
  macroFilters = [],
  suggestedMacros = [],
  intents = [],
  ticketContext = {},
  zendeskTicket = {},
}) => async (dispatch, getState) => {
  const jwt = await dispatch(getJwt());
  const projectId = getProjectId();

  return fetch(
    // `https://europe-west1-${projectId}.cloudfunctions.net/filters/`,
    `https://us-central1-hubware-production.cloudfunctions.net/simonfilters/`,
    {
      method: 'POST',
      body: JSON.stringify({
        macroFilters,
        suggestedMacros,
        intents,
        ticketContext,
        zendeskTicket,
      }),
      headers: {
        'x-hubware-authentication': jwt,
        'Content-Type': 'application/json',
      },
    }
  )
    .then(res => res.json())
    .catch(error => console.error('Error:', error))
    .then(response => response);
};

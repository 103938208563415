import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {Row, Col, Button} from 'reactstrap';
import {RotateCw} from 'react-feather';

import {SAMPLE_SCHEMA} from '../../../selectors/samples';
import {localeDate} from '../../../lib/utils';
import Card from '../../card';

const TitleSection = ({sample, handleSubmit, settingId}) => (
  <Card className="mb-4">
    <div className="card-body">
      <div className="d-flex justify-content-between">
        <div>
          <h5 className="card-title">Samples #{sample.id}</h5>
          <h6 className="card-subtitle mb-2 text-muted">
            Last analysis on {get(sample, 'res.0.creation')}
          </h6>
        </div>
        <div>
          <Button color="hubware" onClick={handleSubmit}>
            <RotateCw size="15" className="mr-2" />
            New analysis
          </Button>
        </div>
      </div>
      <hr />
      <Row>
        <Col>
          <p>
            <strong>Timeframe</strong>: From {localeDate(sample.dataset.start)}{' '}
            to {localeDate(sample.dataset.end)}
          </p>
          <p>
            <strong>Size</strong>: {sample.dataset.size} message
            {sample.dataset.size > 1 && 's'}
            {sample.dataset.size !== sample.requestedSize &&
              sample.requestedSize && (
                <small>
                  {' '}
                  /{sample.requestedSize}
                  &nbsp;requested&nbsp;messages
                </small>
              )}
          </p>
          <p>
            <strong>Metabase freemium analysis</strong>:{' '}
            <a
              href={`https://metabase.hubwa.re/dashboard/16?setting=${settingId}&creation=${sample.dataset.start.substr(
                0,
                10
              )}~${sample.dataset.end.substr(0, 10)}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              link
            </a>
          </p>
        </Col>
        <Col>
          <p>
            <strong>Analysis creation</strong>: {sample.analysisCreation || '-'}
          </p>
          <p>
            <strong>Intent</strong>: {sample.dataset.intent}
          </p>
        </Col>
      </Row>
    </div>
  </Card>
);

export default connect(state => ({
  settingId: state.firestore.setting.id,
}))(TitleSection);

TitleSection.propTypes = {
  sample: SAMPLE_SCHEMA,
  handleSubmit: PropTypes.func.isRequired,
  settingId: PropTypes.string.isRequired,
};

import React from 'react';
import ModelDetails from '../../components/ai/model';

export default () => (
  <div className="card-body">
    <h5 className="card-title">Active Model</h5>
    <h6 className="card-subtitle mb-2 text-muted">A.I. model evaluations</h6>
    <ModelDetails />
  </div>
);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {kebabCase} from 'lodash';
import {AlertTriangle, CheckCircle, Check, X, AlertCircle} from 'react-feather';
import {UncontrolledCollapse, Col, Row, Button} from 'reactstrap';

import {percent} from '../../../../lib/utils';

export default function Item({macroUsed, datasetLength}) {
  const {id, title, usage, accSugg, inaccSugg, noSuggestions} = macroUsed;
  const usagePrct = percent(usage / datasetLength);
  const accuracy = accSugg / usage;
  const noSuggestionsPrct = percent(noSuggestions / usage);
  const inaccSuggUsage = inaccSugg.reduce(
    (count, sugg) => count + sugg.usage,
    0
  );
  const inaccSuggUsagePrct = percent(inaccSuggUsage / usage);

  // TODO: REMOVE CASES WHERE id === 'auto-replied (bug)' IN THIS FILE
  const color =
    accuracy >= 0.8
      ? 'success'
      : accuracy >= 0.4 || id === 'auto-replied (bug)'
        ? 'dark'
        : 'danger';
  const iconProps = {size: 20, className: `text-${color} mr-2`};

  return (
    <StyledRow>
      <Col md="5" className="d-flex align-items-center">
        <div>
          {color === 'success' && <CheckCircle {...iconProps} />}
          {color === 'danger' && <AlertCircle {...iconProps} />}
          {color === 'dark' && <AlertTriangle {...iconProps} />}
        </div>
        <div>
          {id === 'auto-replied (bug)'
            ? 'auto-replied (bug)'
            : id === 'null'
              ? 'No macro used'
              : title}
          <small className="ml-2">({id})</small>
        </div>
      </Col>

      <Col md="2">
        {usagePrct}% <small>({usage} tickets)</small>
      </Col>

      <Col className={`text-${color}`}>
        {id === 'auto-replied (bug)' ? '-' : percent(accuracy)}%
      </Col>

      <Col md="2">
        <Button color="secondary" id={`id-${kebabCase(id)}`} outline>
          Explore
        </Button>
      </Col>

      <Col md="12">
        <UncontrolledCollapse
          exit={false}
          enter={false}
          className="p-4"
          toggler={`#id-${kebabCase(id)}`}
        >
          {Boolean(accSugg) && (
            <div>
              <dt>
                <Check size="12" className="text-success mr-2" />
                Macro suggested would be used
              </dt>
              <dd className="ml-4">
                {accSugg} tickets - {percent(accuracy)}%
              </dd>
            </div>
          )}

          {Boolean(noSuggestions) && (
            <div>
              <dt>
                <X size="12" className="text-danger mr-2" />
                Nothing would be suggested
              </dt>
              <dd className="ml-4">
                {noSuggestions} tickets - {noSuggestionsPrct}%
              </dd>
            </div>
          )}

          {Boolean(inaccSugg.length) && (
            <div>
              <dt>
                {id === 'auto-replied (bug)' && (
                  <span className="text-warning mr-2">?</span>
                )}
                {id !== 'auto-replied (bug)' && (
                  <X size="12" className="text-danger mr-2" />
                )}
                {id === 'auto-replied (bug)'
                  ? "Tickets was auto-replied but due to a temporary bug we can't determine the accuracy"
                  : "Macros would be suggested but wouldn't be used"}
              </dt>
              <dd className="ml-4">
                {inaccSuggUsage} tickets - {inaccSuggUsagePrct}
                %. Macros suggested:
                <ul className="my-2">
                  {inaccSugg.map(sugg => (
                    <li key={sugg.id}>
                      {sugg.title} <small>({sugg.id})</small>:{' '}
                      {percent(sugg.usage / inaccSuggUsage)}%{' '}
                      <small>({sugg.usage} tickets)</small>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          )}
        </UncontrolledCollapse>
      </Col>
    </StyledRow>
  );
}

const StyledRow = styled(Row).attrs({
  className: 'p-3 d-flex align-items-center',
})`
  border-bottom: 1px solid var(--gray-300);

  &:first-child {
    border-top: 1px solid var(--gray-300);
  }
`;

Item.propTypes = {
  macroUsed: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    usage: PropTypes.number.isRequired,
    accSugg: PropTypes.number.isRequired,
    noSuggestions: PropTypes.number.isRequired,
    inaccSugg: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        usage: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  datasetLength: PropTypes.number.isRequired,
};

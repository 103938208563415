import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createLogger} from 'redux-logger';
import {createStore, applyMiddleware, compose} from 'redux';
import {BrowserRouter} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import thunk from 'redux-thunk';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import './styles/custom-bootstrap.scss';
import './styles/main.scss';

import routes from './routes';
import reducer from './reducer';
import * as serviceWorker from './register-service-worker';
import {getAppSetting} from './lib/utils';

const {
  location: {hostname},
} = window;

if (getAppSetting('production')) {
  firebase.initializeApp(require('./config/production.json'));
} else if (getAppSetting('staging')) {
  firebase.initializeApp(require('./config/staging.json'));
} else if (hostname === 'app.hubwa.re') {
  firebase.initializeApp(require('./config/production.json'));
} else {
  firebase.initializeApp(require('./config/staging.json'));
}
firebase.firestore();

if (getAppSetting('debug')) {
  window.firebase = firebase;
}

// Middlewares
// –––––
const logger = createLogger({
  predicate: (getState, action) => getAppSetting('debug'),
});
const middlewares = [thunk, logger];

// Store
// –––––
const store = createStore(reducer, compose(applyMiddleware(...middlewares)));
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">{renderRoutes(routes)}</BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();

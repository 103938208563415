import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import uuidv1 from 'uuid';
import {fill} from 'lodash';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';

import {INTENTS_SCHEMA, getIntents} from '../../../selectors/intents';

class NewSampleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 100,
      startDate: '',
      intent: undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange({target}) {
    const {name, value} = target;
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.submit({...this.state});
  }

  render() {
    const {size, startDate, intent} = this.state;
    const {intents} = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="size" className="mt-4">
            Size
          </Label>
          <StyledInput
            onChange={this.handleChange}
            type="select"
            name="size"
            id="size"
            bsSize="lg"
            value={size}
          >
            {fill(new Array(10), 1).map((i, j) => (
              <option key={uuidv1()}>{(j + 1) * 100}</option>
            ))}
          </StyledInput>
          <p className="text-muted mt-2 ml-1">
            Select the sample size between 100 and 1000 responses. The more you
            get, the slower will be the analysis
          </p>
        </FormGroup>
        <FormGroup>
          <Label for="startDate" className="mt-4">
            Timeframe start date
          </Label>
          <StyledInput
            onChange={this.handleChange}
            type="date"
            name="startDate"
            id="startDate"
            bsSize="lg"
            value={startDate}
            required
          />
          <p className="text-muted mt-2 ml-1">
            Set the stard date from which responses will be retreived
          </p>
        </FormGroup>
        <Label for="intent" className="mt-4">
          Intent
        </Label>
        <StyledInput
          onChange={this.handleChange}
          type="select"
          name="intent"
          id="intent"
          value={intent}
          bsSize="lg"
        >
          <option value={undefined}>All messages</option>
          <optgroup label="Filter on intent">
            {intents.map(intent => (
              <option key={uuidv1()} value={intent.key}>
                {intent.title}
              </option>
            ))}
          </optgroup>
        </StyledInput>
        <p className="text-muted mt-2 ml-1">
          Select an intent if you want to restrict responses on a specific
          intent
        </p>

        <Button type="submit" color="hubware" size="lg" className="mt-4">
          Create
        </Button>
      </Form>
    );
  }
}

const StyledInput = styled(Input)`
  max-width: 211px;
`;

export default connect((state, props) => ({
  intents: getIntents(),
}))(NewSampleForm);

NewSampleForm.propTypes = {
  intents: INTENTS_SCHEMA,
  submit: PropTypes.func.isRequired,
};

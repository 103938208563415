import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Route} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';

import {isUndefined} from 'lodash';
import styled from 'styled-components';

import {resetStore, setAuth} from './actions/app';
import {getJwt} from './actions/data';
import SignIn from './views/sign-in';
import Header from './components/architecture/header';
import Debugger from './components/architecture/debugger';
import Error from './views/error';
import Loading from './views/loading';

class App extends Component {
  constructor(props) {
    super(props);
    props.resetStore();
    if (!this.props.offline) {
      props.setAuth(props.history);
    } else if (!this.props.debug) {
      this.props.getJwt();
    }

    this.isAppLoading = this.isAppLoading.bind(this);
    this.isUserSignedIn = this.isUserSignedIn.bind(this);
  }

  isAppLoading() {
    const {loggedUser, firestore = {}} = this.props;
    if (isUndefined(loggedUser.uid)) {
      return false;
    }

    return (
      isUndefined(firestore.setting) ||
      isUndefined(firestore.zendeskMacros) ||
      isUndefined(firestore.zendeskAuthors) ||
      isUndefined(firestore.user)
    );
  }

  isUserSignedIn() {
    return Boolean(this.props.loggedUser.uid);
  }

  render() {
    const {route, error, offline} = this.props;

    if (error) {
      return <Error error={error} />;
    }

    if (!offline) {
      if (this.isAppLoading()) {
        return <Loading />;
      }

      if (!this.isUserSignedIn()) {
        return (
          <Route
            path="/sign-in"
            exact={true}
            render={props => <SignIn />}
            search={window.location.search}
          />
        );
      }
    }
    return (
      <div className="App">
        <Route component={Header} />{' '}
        {/*<Route> needed to update nested navlink*/}
        <MainWrapper>{renderRoutes(route.routes)}</MainWrapper>
        <Debugger />
      </div>
    );
  }
}

export default connect(
  state => ({
    firestore: state.firestore,
    loggedUser: state.app.loggedUser,
    error: state.app.error,
    debug: state.app.debug,
    offline: state.app.offline,
  }),
  dispatch => ({
    ...bindActionCreators(
      {
        resetStore,
        setAuth,
        getJwt,
      },
      dispatch
    ),
  })
)(App);

App.propTypes = {
  route: PropTypes.object.isRequired,
  resetStore: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  firestore: PropTypes.object,
  loggedUser: PropTypes.object,
  error: PropTypes.string,
  debug: PropTypes.bool.isRequired,
  offline: PropTypes.bool.isRequired,
  getJwt: PropTypes.func.isRequired,
};

const MainWrapper = styled.div`
  .container,
  .container-fluid {
    margin-top: 30px;
    &:first-child {
      margin-top: 60px;
    }
  }
`;

import {DateTime} from 'luxon';
import queryString from 'query-string';

export const percent = (fl = 0) => {
  const res = (Math.round(fl * 10000) / 100).toFixed(2);
  return isNaN(res) ? '-' : res;
};

export const getAppSetting = param =>
  queryString.parse(window.location.search)[param] === 'true' ||
  localStorage.getItem(param) === 'true';

export const localeDate = iso => DateTime.fromISO(iso).toLocaleString();

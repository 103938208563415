import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Card from '../../../components/card';

import ProgressModal from '../../../components/samples/progress-modal';
import NewSampleForm from '../../../components/samples/new/form';
import Errors from '../../../components/samples/new/errors';
import doSample from '../../../components/samples/new/do-sample';

import {fetchSamples} from '../../../lib/firestore/samples';

class newSample extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();

    this.handleProgress = this.handleProgress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  initialState() {
    return {
      progress: 0,
      isModalOpen: false,
      errors: [],
      resultsLength: undefined,
      formState: {},
    };
  }

  handleProgress(progress) {
    this.setState({progress});
  }

  async handleSubmit(formState) {
    await this.setState({...this.initialState(), formState});
    await doSample.bind(this)();
  }

  render() {
    const {isModalOpen, progress, errors, resultsLength} = this.state;

    return (
      <div className="container">
        <Card>
          <div className="card-body">
            <h5 className="card-title">New sample</h5>
            <Errors errors={errors} resultsLength={resultsLength} />
            <NewSampleForm submit={this.handleSubmit} />
            <ProgressModal isOpen={isModalOpen} progress={progress} />
          </div>
        </Card>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    uid: state.firestore.user.id,
    settingId: state.firestore.setting.id,
  }),
  dispatch => ({
    ...bindActionCreators({fetchSamples}, dispatch),
  })
)(newSample);

newSample.propTypes = {
  settingId: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  fetchSamples: PropTypes.func.isRequired,
};

import appActions from '../actions/app';
import firestoreActions from '../actions/firestore';
import {parseObjectWithDates} from '../lib/firestore/utils';

const schemas = require('@hubware/joi-firebase')(require('joi-browser'));

const {RESET_STORE} = appActions;
const {
  SET_USER,
  SET_SETTING,
  SET_MACROS,
  SET_AUTHORS,
  ADD_REALTIME_REF,
  RESET_REALTIME_REF,
  SET_SAMPLES,
} = firestoreActions;

const initialState = {
  setting: undefined,
  zendeskMacros: undefined,
  zendeskAuthors: undefined,
  user: undefined,
  samples: undefined,
  realtimeRefs: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER: {
      const result = schemas.user.validate(parseObjectWithDates(action.user));
      if (result.error) {
        console.error(result.error);
      }
      return {
        ...state,
        user: {...result.value, id: action.id},
      };
    }
    case SET_SETTING: {
      const result = schemas.settings.validate(
        parseObjectWithDates(action.setting)
      );
      if (result.error) {
        throw result.error;
      }
      return {
        ...state,
        setting: result.value,
      };
    }
    case SET_SAMPLES: {
      return {
        ...state,
        samples: action.samples,
      };
    }
    case SET_MACROS: {
      return {
        ...state,
        zendeskMacros: action.zendeskMacros,
      };
    }
    case SET_AUTHORS: {
      return {
        ...state,
        zendeskAuthors: action.zendeskAuthors,
      };
    }
    case ADD_REALTIME_REF: {
      return {
        ...state,
        realtimeRefs: [...state.realtimeRefs, action.ref],
      };
    }
    case RESET_REALTIME_REF: {
      return {
        ...state,
        realtimeRefs: [],
      };
    }
    default:
      return state;
  }
}

const rootReducer = (state = initialState, action) => {
  if (action.type === RESET_STORE) {
    if (!action.stores || action.stores.includes('firestore')) {
      state = undefined;
    }
  }
  return reducer(state, action);
};

export default rootReducer;

import PropTypes from 'prop-types';
import {createSelector} from 'reselect';
import {upperFirst} from 'lodash';

export const MACRO_TITLE_SCHEMA = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

export const MACROS_TITLES_SCHEMA = PropTypes.arrayOf(MACRO_TITLE_SCHEMA);

const getMacros = state => {
  const {zendeskMacros: macros} = state.firestore;
  if (!macros) {
    return undefined;
  }
  return macros;
};

export const getMacrosTitles = createSelector(
  [getMacros],
  (macros = []) =>
    macros.map(({id, title: rawTitle}) => ({
      id: String(id),
      rawTitle,
      ...parseMacroCategory(rawTitle),
    }))
);

export function parseMacroTitle(rawTitle = '') {
  return upperFirst(
    rawTitle
      .substr(0, rawTitle.indexOf('::'))
      .trim()
      .toLowerCase()
  );
}

export function parseMacroCategory(rawTitle = '') {
  const res = rawTitle.split('::').map(r => upperFirst(r.trim().toLowerCase()));

  return {
    title: res[1] || res[0],
    category: res[1] ? res[0] : undefined,
  };
}

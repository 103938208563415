import PropTypes from 'prop-types';
import {createSelector} from 'reselect';
import {orderBy} from 'lodash';
import intents from '../temp/intents';

export const INTENTS_SCHEMA = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    special: PropTypes.bool,
  })
);

const getAllIntents = () => orderBy(intents, 'key');

export const getIntents = createSelector(
  [getAllIntents, special => special],
  (intents, special) => intents.filter(i => (special ? i : !i.special))
);

import React from 'react';
import PropTypes from 'prop-types';
import {renderRoutes} from 'react-router-config';
import {Switch, Redirect} from 'react-router-dom';

export default function Intents({route}) {
  return (
    <div className="container">
      {renderRoutes(route.routes)}
      <Switch>
        <Redirect
          exact
          from="/intents"
          to={{
            pathname: '/intents /list',
            search: window.location.search,
          }}
        />
      </Switch>
    </div>
  );
}

Intents.propTypes = {
  route: PropTypes.object.isRequired,
};

import {mapValues, isPlainObject} from 'lodash';

// Utils
// -----
export const collectionToData = querySnapshot =>
  querySnapshot.docs.map(docToData);

export const docToData = docSnapshot =>
  Object.assign(
    {},
    docSnapshot.data(),
    docSnapshot.data().id ? null : {id: docSnapshot.id} // Avoid replace existing "id" field in object
  );

export const parseObjectWithDates = obj =>
  mapValues(obj, val => {
    if (isPlainObject(val)) {
      return parseObjectWithDates(val);
    }
    if (!val) {
      return val;
    }
    if (typeof val.toDate === 'function') {
      return val.toDate();
    }
    return val;
  });

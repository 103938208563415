import styled from 'styled-components';

export default styled.div.attrs({
  className: 'card',
})`
  margin-bottom: 60px;

  .card-title {
    font-weight: 600;
  }

  input,
  select {
    background-color: $body-bg;
    font-size: 1rem;
  }

  label {
    font-weight: 600;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'reactstrap';

export default function Errors({errors, resultsLength}) {
  return (
    <div>
      {errors.length > 0 && <Alert color="danger">An error occured</Alert>}
      {resultsLength === 0 && <Alert color="warning">No results</Alert>}
    </div>
  );
}

Errors.propTypes = {
  errors: PropTypes.array.isRequired,
  resultsLength: PropTypes.number,
};

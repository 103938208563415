import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import Card from '../../components/card';
import uuidv1 from 'uuid';
import {Info} from 'react-feather';
import {UncontrolledTooltip} from 'reactstrap';

import {MACROS_TITLES_SCHEMA, getMacrosTitles} from '../../selectors/macros';
import INTENTS from './intents.json';

import styled from 'styled-components';

function IntentsList({macrosTitles}) {
  return (
    <Card>
      <div className="card-body">
        <h5 className="card-title">Intents</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          Select intent you want to configure
        </h6>

        <Row className="mt-4">
          {INTENTS.map(({key, title, description}) => (
            <Col md="4" key={uuidv1()}>
              <StyledIntent
                to={{
                  pathname: `intents/${key}`,
                  search: window.location.search,
                }}
              >
                <h6>
                  {title || key}
                  <Info id={key} size="12" className="text-muted clic ml-1" />
                </h6>
                <UncontrolledTooltip target={key}>
                  {description}
                </UncontrolledTooltip>
                <small className="text-muted">
                  Auto: {Math.floor(Math.random() * Math.floor(4))} - Prepared:{' '}
                  {Math.floor(Math.random() * Math.floor(4))} - Assisted:{' '}
                  {Math.floor(Math.random() * Math.floor(4))}
                </small>
              </StyledIntent>
            </Col>
          ))}
        </Row>
      </div>
    </Card>
  );
}

export default connect((state, props) => ({
  macrosTitles: getMacrosTitles(state),
}))(IntentsList);

IntentsList.propTypes = {
  macrosTitles: MACROS_TITLES_SCHEMA,
};

const StyledIntent = styled(Link).attrs({
  className: 'clic p-3 mb-3',
})`
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  color: var(--body-color);
  display: block;

  &:hover {
    border-color: var(--hubware);
    color: var(--body-color);
    text-decoration: none;
  }
`;

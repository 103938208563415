import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import {Plus} from 'react-feather';
import {get} from 'lodash';

import {SAMPLE_SCHEMA, getFormatedtSamples} from '../../../selectors/samples';
import {fetchSamples} from '../../../lib/firestore/samples';

import Card from '../../../components/card';
import SamplesList from '../../../components/samples/list';

const Samples = ({samples, fetchSamples}) => {
  const RESPONSES_LIMIT = 3000;
  if (!samples) {
    fetchSamples();
  }

  const totalResponse = (samples || []).reduce(
    (sum, sample) => sum + get(sample, 'dataset.size', 0),
    0
  );

  return (
    <div className="container">
      <Card>
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="card-title">Samples analysis</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                List of all samples and analysis results
              </h6>
            </div>
            <div>
              {samples &&
                totalResponse < RESPONSES_LIMIT && (
                  <Link
                    className="btn btn-hubware"
                    to={{
                      pathname: `new`,
                      search: window.location.search,
                    }}
                  >
                    <Plus size="15" className="mr-2" />
                    New sample
                  </Link>
                )}
            </div>
          </div>
          <SamplesList samples={samples} />
          {samples &&
            totalResponse >= RESPONSES_LIMIT && (
              <p className="text-center text-muted">
                {RESPONSES_LIMIT} responses max
              </p>
            )}
        </div>
      </Card>
    </div>
  );
};

export default connect(
  (state, props) => ({
    samples: getFormatedtSamples(state),
  }),
  dispatch => ({
    ...bindActionCreators({fetchSamples}, dispatch),
  })
)(Samples);

Samples.propTypes = {
  samples: PropTypes.arrayOf(SAMPLE_SCHEMA),
  fetchSamples: PropTypes.func.isRequired,
};

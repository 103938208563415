import React from 'react';
import styled from 'styled-components';
import {Row, Col} from 'reactstrap';

import Debugger from '../components/architecture/debugger';

export default () => (
  <CenteredRow>
    <Col>loading</Col>
    <Debugger />
  </CenteredRow>
);

const CenteredRow = styled(Row).attrs({
  className: 'align-items-center text-center m-0',
})`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {get, find} from 'lodash';

import {getJwt} from '../../../actions/data';
import {analyseResponse} from '../../../lib/analysis';
import {
  fetchAllSampleResults,
  fetchSamples,
} from '../../../lib/firestore/samples';

import {SAMPLE_SCHEMA, getFormatedtSamples} from '../../../selectors/samples';
import doAnalysis from '../../../components/samples/details/do-analysis';

import TitleSection from '../../../components/samples/details/title-section';
import ResultsTable from '../../../components/samples/details/results-table';
import Summary from '../../../components/samples/details/summary';
import ProgressModal from '../../../components/samples/progress-modal';

class viewSample extends Component {
  constructor(props) {
    super(props);
    this.redirectIfNoSample = this.redirectIfNoSample.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSampleResults = this.getSampleResults.bind(this);

    this.redirectIfNoSample();
    this.state = this.initialState();
  }

  redirectIfNoSample() {
    const {sample, history} = this.props;
    if (!sample) {
      history.push({
        pathname: '/analysis/samples/list',
        search: window.location.search,
      });
    }
  }

  initialState() {
    return {
      progress: 0,
      isModalOpen: false,
      results: undefined,
    };
  }

  componentDidMount() {
    this.getSampleResults();
  }

  handleProgress(progress) {
    this.setState({progress});
  }

  async handleSubmit() {
    await this.setState({...this.initialState()});
    await doAnalysis.bind(this)();
  }

  async getSampleResults() {
    if (this.props.sample) {
      const sampleResults = await fetchAllSampleResults(this.props.sample.id);
      this.setState({results: sampleResults});
    }
  }

  render() {
    const {sample, sampleId} = this.props;
    const {isModalOpen, progress, results} = this.state;

    if (!sample) {
      return null;
    }
    return (
      <div className="container-fluid">
        <TitleSection sample={sample} handleSubmit={this.handleSubmit} />
        {!results && <div className="text-center">Loading...</div>}
        {results &&
          results.length === 0 && (
            <div className="text-center">No restults</div>
          )}
        {results &&
          results.length > 0 && (
            <Fragment>
              <Summary results={results} />
              <ResultsTable data={results} sampleId={sampleId} />
            </Fragment>
          )}
        <ProgressModal isOpen={isModalOpen} progress={progress} />
      </div>
    );
  }
}

export default connect(
  (state, props) => {
    const id = get(props.match, 'params.id');
    const samples = getFormatedtSamples(state);
    return {
      sampleId: id,
      sample: find(samples, {id}),
    };
  },
  dispatch => ({
    ...bindActionCreators(
      {
        analyseResponse,
        fetchSamples,
        getJwt,
      },
      dispatch
    ),
  })
)(viewSample);

viewSample.propTypes = {
  sample: SAMPLE_SCHEMA,
  sampleId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  analyseResponse: PropTypes.func.isRequired,
  fetchSamples: PropTypes.func.isRequired,
  getJwt: PropTypes.func.isRequired,
};

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {get} from 'lodash';

const TicketId = ({id, zendeskDomain}) => (
  <a
    href={`https://${zendeskDomain}.zendesk.com/agent/tickets/${id}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {id}
  </a>
);

export default connect((state, props) => ({
  zendeskDomain: get(state, 'firestore.setting.zendesk.domain'),
}))(TicketId);

TicketId.propTypes = {
  id: PropTypes.string.isRequired,
  zendeskDomain: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Home, Cpu, Send, Database} from 'react-feather';
import {Navbar, Nav, NavItem, NavbarToggler, Collapse} from 'reactstrap';
import {get} from 'lodash';

import NavLink from '../nav-link';
import logo from './logo.svg';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <StyledHeader>
        <Link
          className="mr-4 mb-1 navbar-brand"
          to={{
            pathname: '/',
            search: window.location.search,
          }}
        >
          <img src={logo} alt="logo" height="25px" width="22px" />
        </Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink pathname="/home" exact>
                <Home width={14} className="align-bottom" />
                Home
              </NavLink>
            </NavItem>

            {this.props.neuralConfig && (
              <NavItem>
                <NavLink pathname="/ai">
                  <Cpu width={14} className="align-bottom" />
                  A.I
                </NavLink>
              </NavItem>
            )}

            <NavItem>
              <NavLink pathname="/intents">
                <Send width={14} className="align-bottom" />
                Responses
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink pathname="/analysis">
                <Database width={14} className="align-bottom" />
                Analysis
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </StyledHeader>
    );
  }
}

export default connect(state => ({
  neuralConfig: get(state, 'firestore.setting.apiPrediction.configName'),
}))(Header);

Header.propTypes = {
  neuralConfig: PropTypes.string,
};

const StyledHeader = styled(Navbar).attrs({
  color: 'light',
  id: 'header',
  className: 'py-0',
  expand: 'md',
  light: true,
})`
  background-color: var(--white) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

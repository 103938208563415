import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {chain, filter, map, fill} from 'lodash';
import uuidv1 from 'uuid';
import {Col, Row, Input} from 'reactstrap';

import Card from '../../../card';
import {
  MACROS_TITLES_SCHEMA,
  getMacrosTitles,
} from '../../../../selectors/macros';
import {getMacroTitle} from '../../../../lib/macros';
import Item from './summary-item';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      threshold: 0.05,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({target}) {
    const {name, value} = target;
    this.setState({[name]: value});
  }

  render() {
    const {threshold} = this.state;
    const {macrosTitles, results} = this.props;
    const mostMacroUsed = getMostMacroUsed(macrosTitles, results, threshold);
    return (
      <Card>
        <div className="card-body">
          <h5 className="card-title">Summary</h5>
          <h6 className="card-subtitle mb-2 text-muted">
            The following macros are used for more than
            <StyledInput
              onChange={this.handleChange}
              type="select"
              name="threshold"
              id="threshold"
              bsSize="sm"
              value={threshold}
            >
              {fill(new Array(11), 1).map((i, j) => (
                <option value={j / 100} key={uuidv1()}>
                  {j}%
                </option>
              ))}
            </StyledInput>{' '}
            of the replies.
          </h6>
          <Row className="p-3">
            <Col md="5">Macro used</Col>
            <Col md="2">Usage</Col>
            <Col md="2">Accuracy</Col>
            <Col md="3" />
          </Row>
          <div>
            {mostMacroUsed.map(macroUsed => (
              <Item
                key={macroUsed.id}
                macroUsed={macroUsed}
                datasetLength={results.length}
              />
            ))}
          </div>
        </div>
      </Card>
    );
  }
}

export default connect((state, props) => ({
  macrosTitles: getMacrosTitles(state),
}))(Summary);

Summary.propTypes = {
  results: PropTypes.array.isRequired,
  macrosTitles: MACROS_TITLES_SCHEMA,
};

const StyledInput = styled(Input)`
  display: inline;
  width: auto;
  border-width: 0;
  border-bottom-width: 1px;
`;

function getMostMacroUsed(macrosTitles, results, threshold) {
  return chain(results)
    .groupBy('response.macroUsed')
    .pickBy(v => v.length / results.length >= threshold)
    .map((sugg, id) => {
      const accSugg = filter(sugg, {isSuggestionAccurate: true}).length;
      const noSuggestions = filter(sugg, m => m.suggestedMacros.length === 0)
        .length;
      const inaccSuggGrouped = chain(sugg)
        .filter(
          s => s.isSuggestionAccurate !== true && s.suggestedMacros.length !== 0
        ) // !== true in order to handle "maybe(bug)"
        .groupBy('suggestedMacros')
        .value();
      const inaccSugg = map(inaccSuggGrouped, (sugg, id) => ({
        id,
        title: id.split(',').map(id => getMacroTitle(macrosTitles, id)),
        usage: sugg.length,
      }));

      return {
        id,
        title: getMacroTitle(macrosTitles, id),
        usage: sugg.length,
        accSugg,
        noSuggestions,
        inaccSugg,
      };
    })
    .value();
}

import React from 'react';
import PropTypes from 'prop-types';
import {UncontrolledDropdown, DropdownToggle, DropdownMenu} from 'reactstrap';
import {snakeCase} from 'lodash';
import {AlignJustify} from 'react-feather';
import styled from 'styled-components';

export default function ColumnsDropdown({columns, toggleColumn}) {
  if (!columns || columns.length === 0) {
    return null;
  }

  return (
    <UncontrolledDropdown setActiveFromChild>
      <MainDropdown tag="a" className="btn btn-link btn-sm mb-2">
        Columns <AlignJustify size="15" className="mr-2" />
      </MainDropdown>
      <StyledDropdownMenu>
        <ul className="m-0 px-2">
          {columns.map((column, index) => (
            <li key={snakeCase(column.Header)} className="form-check">
              <input
                type="checkbox"
                checked={column.display}
                id={snakeCase(column.Header)}
                className="form-check-input"
                onChange={() => toggleColumn(index)}
              />
              <label
                className="form-check-label"
                htmlFor={snakeCase(column.Header)}
              >
                {column.Header}
              </label>
            </li>
          ))}
        </ul>
      </StyledDropdownMenu>
    </UncontrolledDropdown>
  );
}

ColumnsDropdown.propTypes = {
  columns: PropTypes.array,
  toggleColumn: PropTypes.func.isRequired,
};

const MainDropdown = styled(DropdownToggle)`
  border: 1px solid var(--gray-300);
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  min-width: 250px;
`;

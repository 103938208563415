import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Card from '../../components/card';

function Intents() {
  return (
    <Card>
      <div className="card-body">
        <h5 className="card-title">hello</h5>
        <h6 className="card-subtitle mb-2 text-muted">World</h6>
      </div>
    </Card>
  );
}

export default connect((state, props) => ({}))(Intents);

Intents.propTypes = {
  route: PropTypes.object.isRequired,
};

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Redirect, Switch} from 'react-router-dom';
import {renderRoutes} from 'react-router-config';
import {Nav, NavItem} from 'reactstrap';
import {get} from 'lodash';

import {getCurrentModelVersion, getEvalJobsByModel} from '../../lib/model';
import {setEvalJobs} from '../../actions/data';

import Card from '../../components/card';
import NavLink from '../../components/nav-link';

class Ai extends Component {
  constructor(props) {
    super(props);
    this.fetchEvalJobs = this.fetchEvalJobs.bind(this);
    this.state = {
      errors: undefined,
      loading: false,
    };
  }

  async fetchEvalJobs() {
    this.setState({loading: true});
    const {settingId, neuralConfig, setEvalJobs} = this.props;
    const {currentModel} = await getCurrentModelVersion(neuralConfig);
    const {errors, evalJobs} = await getEvalJobsByModel(
      settingId,
      currentModel,
      neuralConfig
    );

    if (errors) {
      return this.setState({errors});
    }

    setEvalJobs('current', evalJobs);
  }

  async componentDidMount() {
    const shouldFetchEvalJobs = !this.props.evalJobs.current;
    if (shouldFetchEvalJobs) {
      await this.setState({loading: shouldFetchEvalJobs});
      await this.fetchEvalJobs();
      await this.setState({loading: false});
    }
  }

  render() {
    const {errors, loading} = this.state;
    if (!this.props.neuralConfig) {
      return 'Feature disabled for this account';
    }
    if (errors) {
      return 'An error occured, please retry later';
    }
    if (loading) {
      return 'Loading...';
    }

    return (
      <div className="container">
        <Card>
          <Nav>
            <NavItem>
              <NavLink pathname="/ai/model">Model</NavLink>
            </NavItem>
            <NavItem>
              <NavLink pathname="/ai/intents">Intents</NavLink>
            </NavItem>
          </Nav>
          {renderRoutes(this.props.route.routes)}

          <Switch>
            <Redirect
              exact
              from="/ai"
              to={{
                pathname: '/ai/model',
                search: window.location.search,
              }}
            />
          </Switch>
        </Card>
      </div>
    );
  }
}

export default connect(
  state => ({
    settingId: state.firestore.setting.id,
    neuralConfig: get(state, 'firestore.setting.apiPrediction.configName'),
    evalJobs: state.data.ai.evalJobs,
  }),
  dispatch => ({
    ...bindActionCreators(
      {
        setEvalJobs,
      },
      dispatch
    ),
  })
)(Ai);

Ai.propTypes = {
  route: PropTypes.object.isRequired,
  settingId: PropTypes.string.isRequired,
  neuralConfig: PropTypes.string.isRequired,
  evalJobs: PropTypes.object.isRequired,
  setEvalJobs: PropTypes.func.isRequired,
};

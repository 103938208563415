import {combineReducers} from 'redux';

import app from './app';
import data from './data';
import firestore from './firestore';

const reducer = combineReducers({
  app,
  data,
  firestore,
});

export default reducer;

import {pick} from 'lodash';

import appActions from '../actions/app';
import {getAppSetting} from '../lib/utils';

const {
  RESET_STORE,
  SET_IS_ADMIN,
  SET_LOGGED_USER,
  SET_ERROR,
  REFRESH_PARAMS,
} = appActions;

const initialState = {
  debug: getAppSetting('debug'),
  offline: getAppSetting('offline'),
  isAdmin: false,
  loggedUser: {
    uid: undefined,
    photoURL: undefined,
    email: undefined,
    displayName: undefined,
    emailVerified: undefined,
  },
  error: undefined,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_ADMIN: {
      return {
        ...state,
        isAdmin: action.isAdmin,
      };
    }
    case SET_LOGGED_USER: {
      return {
        ...state,
        loggedUser: {
          ...pick(action.user, [
            'uid',
            'photoURL',
            'email',
            'displayName',
            'emailVerified',
          ]),
        },
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case REFRESH_PARAMS: {
      return {
        ...state,
        debug: getAppSetting('debug'),
        offline: getAppSetting('offline'),
      };
    }
    default:
      return state;
  }
}

const rootReducer = (state = initialState, action) => {
  if (action.type === RESET_STORE) {
    if (!action.stores || action.stores.includes('app')) {
      state = undefined;
    }
  }
  return reducer(state, action);
};

export default rootReducer;

import {get} from 'lodash';
import {graphql} from './callable-functions';
import {getProjectId} from './firebase';

export const getCurrentModelVersion = config => {
  const domain =
    getProjectId().indexOf('production') >= 0 ? 'neural' : 'nightly-neural';

  return fetch(`https://${domain}.hubwa.re/model/version`, {
    method: 'POST',
    body: JSON.stringify({config}),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => res.json());
};

export const getEvalJobsByModel = (settingsId, version, config) =>
  graphql('SearchEvalJobByConfig', {
    version,
    settingsId,
    config,
  }).then(res => ({
    errors: get(res, 'data.errors'),
    evalJobs: get(
      res,
      'data.data.settings.evalJobConnectionByConfig.edges.0.node'
    ),
  }));

import appActions from '../actions/app';
import dataActions from '../actions/data';

const {RESET_STORE} = appActions;
const {SET_JWT, SET_EVAL_JOB} = dataActions;

const initialState = {
  jwt: undefined,
  ai: {
    evalJobs: {
      current: undefined,
      previous: undefined,
    },
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_JWT: {
      return {
        ...state,
        jwt: action.jwt,
      };
    }
    case SET_EVAL_JOB: {
      return {
        ...state,
        ai: {
          ...state.ai,
          evalJobs: {
            ...state.evalJobs,
            [action.key]: action.evalJob,
          },
        },
      };
    }
    default:
      return state;
  }
}

const rootReducer = (state = initialState, action) => {
  if (action.type === RESET_STORE) {
    if (!action.stores || action.stores.includes('data')) {
      state = undefined;
    }
  }
  return reducer(state, action);
};

export default rootReducer;

import PropTypes from 'prop-types';
import {createSelector} from 'reselect';
import {get, map, mapValues} from 'lodash';

import {percent} from '../lib/utils';

const getEvalJobs = state => state.data.ai.evalJobs;

const EVAL_JOB_SCHEMA = PropTypes.shape({
  creation: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  coverage: PropTypes.string.isRequired,
  precision: PropTypes.string.isRequired,
  datasetSize: PropTypes.number.isRequired,
  evalsetSize: PropTypes.number.isRequired,
});

export const EVAL_JOBS_SCHEMA = PropTypes.shape({
  current: EVAL_JOB_SCHEMA,
  previous: EVAL_JOB_SCHEMA,
});

const INTENT_SCHEMA = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    precision: PropTypes.string.isRequired,
    coverage: PropTypes.string.isRequired,
    datasetSize: PropTypes.number.isRequired,
    evalsetSize: PropTypes.number.isRequired,
  })
);

export const INTENTS_SCHEMA = PropTypes.shape({
  current: INTENT_SCHEMA,
  previous: INTENT_SCHEMA,
});

export const getFormatedEvalJobsComparison = createSelector(
  [getEvalJobs],
  evalJobs =>
    mapValues(evalJobs, evaljob => {
      if (!evaljob) {
        return undefined;
      }

      return {
        creation: evaljob.endDate,
        version: evaljob.version,
        precision: percent(get(evaljob, 'coverage.effAccuracy')),
        coverage: percent(get(evaljob, 'coverage.cover')),
        evalsetSize: get(evaljob, 'coverage.nbInsts'),
        datasetSize: get(evaljob, 'training.ithreshold.instance_count'),
      };
    })
);

export const getFormatedIntentsComparison = createSelector(
  [getEvalJobs],
  evalJobs =>
    mapValues(evalJobs, evaljob => {
      if (!evaljob) {
        return undefined;
      }
      const counts = evaljob.counts || [];

      return map(counts, (value, intent) => ({
        key: intent,
        precision: percent(get(evaljob, ['precision', intent])),
        coverage: percent(get(evaljob, ['recall', intent])),
        datasetSize: get(evaljob, [
          'training',
          'ithreshold',
          'label_counts',
          intent,
        ]),
        evalsetSize:
          get(evaljob, ['counts', intent, 'TP']) +
          get(evaljob, ['counts', intent, 'FN']),
      }));
    })
);

import {get, find} from 'lodash';

// KEEP ME, NO USE OF SELECTOR NEEDED
// KEEP ME, NO USE OF SELECTOR NEEDED
// KEEP ME, NO USE OF SELECTOR NEEDED
// KEEP ME, NO USE OF SELECTOR NEEDED
export const getMacroTitle = (macrosTitles, id) =>
  get(find(macrosTitles, {id: String(id)}), 'title', 'macro unknown');

export const isAutoReply = macroId => (dispatch, getState) => {
  // alert('use selector');
  // alert('use selector');
  // alert('use selector');
  // alert('use selector');
  const macroIds = get(
    getState(),
    'firestore.setting.autoReplyConfiguration.macroIds',
    []
  );
  return Boolean(macroIds.find(i => i === macroId));
};

export const getMappedIntentByMacro = macroId => (dispatch, getState) => {
  // alert('use selector');
  // alert('use selector');
  // alert('use selector');
  // alert('use selector');
  const macroIntentPairs = get(
    getState(),
    'firestore.setting.macroIntentPairs',
    []
  );
  const intents = macroIntentPairs
    .filter(({macro}) => String(macro) === macroId)
    .map(({intent}) => intent);
  return intents;
};

export const getMacroById = id => (dispatch, getState) => {
  // alert('use selector');
  // alert('use selector');
  // alert('use selector');
  // alert('use selector');
  const zendeskMacros = get(getState(), 'firestore.zendeskMacros', []);
  return find(zendeskMacros, {id});
};

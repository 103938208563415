import React from 'react';
import PropTypes from 'prop-types';
import {Prompt} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody, Progress} from 'reactstrap';

export default function ProgressModal({isOpen, progress}) {
  return (
    <Modal isOpen={isOpen} centered>
      <Prompt
        when={isOpen}
        message={() =>
          `Operation in progress. It could take several minutes. Are you sure to want to close this window?`
        }
      />
      <ModalHeader>Loading</ModalHeader>
      <ModalBody>
        <h6 className="text-center">
          This operation could take several minutes. Please do not close this
          window.
        </h6>
        <Progress
          className="mt-4"
          animated
          value={progress * 100}
          color="hubware"
        >
          {Math.round(progress * 100)}%
        </Progress>
      </ModalBody>
    </Modal>
  );
}

ProgressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
};

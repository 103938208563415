import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import {pick} from 'lodash';

export default class ExportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: undefined,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  async handleClick() {
    await this.setState({loading: true});

    await navigator.clipboard
      .writeText(JSON.stringify(formatExport(this.props.data)))
      .then(() => this.setState({success: true}))
      .catch(() => this.setState({success: false}));
    await this.setState({loading: false});
  }

  render() {
    const {loading, success} = this.state;
    return (
      <div className={this.props.className}>
        {success === true && (
          <small className="mr-2 text-success">Data copied to clipboard</small>
        )}
        {success === false && (
          <small className="mr-2 text-danger">An error occured</small>
        )}
        <Button
          color="link"
          size="sm"
          onClick={this.handleClick}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Data export'}
        </Button>
      </div>
    );
  }
}

ExportButton.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

function formatExport(data) {
  return data;
  // console.log(data);
  // return {};
  // return data
  //   .map(d =>
  //     pick(d, [
  //       'creation',
  //       'language',
  //       'zendeskTicketId',
  //       'responseDate',
  //       'responseMacroId',
  //       'responseWasAutoReplied',
  //       'zendeskTicket.comments',
  //       'zendeskTicket.status',
  //       'zendeskTicket.subject',
  //     ])
  //   )
  //   .map(
  //     ({
  //       creation,
  //       language,
  //       zendeskTicketId,
  //       responseDate,
  //       responseMacroId,
  //       responseWasAutoReplied,
  //       zendeskTicket,
  //     }) => ({
  //       creation,
  //       language,
  //       zendeskTicketId,
  //       responseDate,
  //       responseMacroId,
  //       responseWasAutoReplied,
  //       ...zendeskTicket,
  //       comments: zendeskTicket.comments.reverse().map(c => c.body),
  //     })
  //   );
}

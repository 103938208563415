import React from 'react';
import PropTypes from 'prop-types';
import {isUndefined} from 'lodash';
import classnames from 'classnames';
import {percent} from '../lib/utils';

export default function comparedValue({value, prevValue, children}) {
  let relativePercent;
  if (!isUndefined(prevValue)) {
    const res = percent(1 - prevValue / value);
    relativePercent = isNaN(res) ? undefined : res;
  }

  return (
    <span>
      <span>
        {value}
        {children}
      </span>
      {relativePercent && (
        <small
          className={classnames('ml-2', {
            'text-success': relativePercent > 0,
            'text-danger': relativePercent < 0,
          })}
        >
          {relativePercent > 0 ? '+' : ''}
          {relativePercent}%
        </small>
      )}
    </span>
  );
}

comparedValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prevValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
};

import {get} from 'lodash';
import {getJwt as getJwtLib} from '../lib/callable-functions';

export const actions = {
  SET_JWT: 'SET_JWT',
  SET_EVAL_JOB: 'SET_EVAL_JOB',
};

export default actions;

export const getJwt = () => async (dispatch, getState) => {
  const jwt = get(getState(), 'data.jwt');
  if (jwt) {
    return jwt;
  }

  const {data} = await getJwtLib(get(getState(), 'firestore.setting.id'));
  await dispatch(setJwt(data));

  return data;
};

export const setJwt = jwt => ({
  type: actions.SET_JWT,
  jwt,
});

export const setEvalJobs = (key, evalJob) => ({
  type: actions.SET_EVAL_JOB,
  key,
  evalJob,
});

import React from 'react';
import GButton from 'react-google-button';

import {signInWithGoogle} from '../lib/firebase';
import Card from '../components/card';

export default () => (
  <div className="container">
    <Card>
      <div className="card-body">
        <h5 className="card-title">Sign-in</h5>
        <GButton
          className="w-100"
          onClick={signInWithGoogle}
          type="light"
          label="Se connecter avec Google"
        />
      </div>
    </Card>
  </div>
);

/* eslint-disable react/prop-types */
import React from 'react';
import {capitalize, snakeCase} from 'lodash';
import uuidv1 from 'uuid';

import MacroUsed from './macro-used';
import TicketId from './ticket-id';
import SuggestedMacro from './suggested-macros';
import ResponseModal from './response-modal';

export default sampleId =>
  [
    {
      Header: 'Ticket Id',
      display: true,
      accessor: 'response.zendeskTicketId',
      Cell: ({value}) => <TicketId id={value} />,
    },
    {
      Header: 'Ticket creation',
      display: false,
      accessor: 'response.creation',
    },
    {
      Header: 'Response creation',
      display: false,
      accessor: 'response.responseDate',
    },
    {
      Header: 'Intent',
      display: true,
      accessor: 'response.intents',
    },
    {
      Header: 'Language',
      display: false,
      accessor: 'response.language',
    },
    {
      Header: 'Macro used',
      display: true,
      accessor: ({response}) => response.macroUsed,
      Cell: ({value}) => <MacroUsed id={value} />,
    },
    {
      Header: 'Response was Auto-reply',
      display: false,
      accessor: ({response}) => response.responseWasAutoReplied.toString(),
      ...buildSelectFilter(),
    },
    {
      Header: 'Suggested macros',
      display: true,
      accessor: 'suggestedMacros',
      Cell: ({value}) => <SuggestedMacro macros={value} />,
    },
    {
      Header: 'Accurate suggestion',
      display: true,
      accessor: ({isSuggestionAccurate}) => isSuggestionAccurate.toString(),
      ...buildSelectFilter(['true', 'false', 'maybe (bug)']),
    },
    {
      Header: 'Suggestion type',
      display: true,
      accessor: ({suggestionType}) => suggestionType.toString(),
      ...buildSelectFilter(['none', 'single', 'multi']),
    },
    {
      Header: 'Would be Auto-reply',
      display: false,
      accessor: ({autoReply}) => autoReply.toString(),
      ...buildSelectFilter(),
    },
    {
      Header: 'Attachment',
      display: false,
      accessor: ({firstCommentHasAttachment}) =>
        firstCommentHasAttachment.toString(),
      ...buildSelectFilter(),
    },
    {
      Header: 'Used macro mapped',
      display: false,
      accessor: ({usedMacroMapped}) => usedMacroMapped.toString(),
      ...buildSelectFilter(),
    },
    {
      Header: 'Used macro mapped on Intent',
      display: false,
      accessor: ({usedMacroMappedOnIntent}) =>
        usedMacroMappedOnIntent.toString(),
      ...buildSelectFilter(),
    },
    {
      Header: 'Details',
      display: true,
      Cell: ({row: {_original: result}}) => (
        <ResponseModal sampleId={sampleId} result={result} />
      ),
    },
  ].map(c => ({...c, id: snakeCase(c.Header)}));

function buildSelectFilter(criterias = ['true', 'false']) {
  return {
    filterMethod: (filter, row) => {
      if (filter.value === 'all') {
        return true;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({filter, onChange}) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{width: '100%'}}
        value={filter ? filter.value : 'all'}
      >
        <option value="all">Show All</option>
        {criterias.map(value => (
          <option key={uuidv1()} value={value}>
            {capitalize(value)}
          </option>
        ))}
      </select>
    ),
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import Card from '../../../card';
import ExportButton from '../export';
import ExportButton2 from '../export-refactorme';
import columns from './columns';
import ColumnsDropdown from './columns-filter-dropdown';

export default class ResultsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicColumns: columns(props.sampleId),
    };
    this.toggleColumn = this.toggleColumn.bind(this);
  }

  toggleColumn(index) {
    const {dynamicColumns} = this.state;
    dynamicColumns[index].display ^= true;
    this.setState({dynamicColumns});
  }

  render() {
    const {data, sampleId} = this.props;
    const {dynamicColumns} = this.state;
    // pivotBy={['macro_used', 'Suggested_macros']}
    return (
      <Card className="mb-4">
        <div className="card-body">
          <div className="d-flex">
            <ColumnsDropdown
              className="ml-3 mr-auto"
              columns={dynamicColumns}
              toggleColumn={this.toggleColumn}
            />
            <ExportButton sampleId={sampleId} />
            <ExportButton2 data={data} />
          </div>
          <ReactTable
            loading={!data}
            data={data}
            columns={dynamicColumns.filter(c => c.display)}
            filterable
            multiSort
          />
        </div>
      </Card>
    );
  }
}

ResultsTable.propTypes = {
  data: PropTypes.array,
  sampleId: PropTypes.string.isRequired,
};

import firebase from 'firebase/app';
import {get} from 'lodash';
import {getUser, onSnapshot, disconnectRealtimeRefs} from '../lib/firestore';
import {getProjectId} from '../lib/firebase';
import {setError} from './app';

export const actions = {
  SET_USER: 'SET_USER',
  SET_SETTING: 'SET_SETTING',
  SET_SAMPLES: 'SET_SAMPLES,',
  SET_MACROS: 'SET_MACROS',
  SET_AUTHORS: 'SET_AUTHORS',
  ADD_REALTIME_REF: 'ADD_REALTIME_REF',
  RESET_REALTIME_REF: 'RESET_REALTIME_REF',
};

export default actions;

export const setUser = (user, id) => ({
  type: actions.SET_USER,
  user,
  id,
});

export const setSetting = setting => ({
  type: actions.SET_SETTING,
  setting,
});

export const setSamples = samples => ({
  type: actions.SET_SAMPLES,
  samples,
});

export const setZendeskMacros = zendeskMacros => ({
  type: actions.SET_MACROS,
  zendeskMacros,
});

export const setZendeskAuthors = zendeskAuthors => ({
  type: actions.SET_AUTHORS,
  zendeskAuthors,
});

export const addRealtimeRef = ref => ({
  type: actions.ADD_REALTIME_REF,
  ref,
});

export const resetRealtimeRef = () => ({
  type: actions.RESET_REALTIME_REF,
});

export const connectFirestore = forceUid => async (dispatch, getState) => {
  let uid;
  if (forceUid) {
    dispatch(disconnectRealtimeRefs());
    uid = forceUid;
  } else {
    uid = get(getState(), 'app.loggedUser.uid');
  }
  // Get user
  const user = await getUser(uid);
  if (!user) {
    return dispatch(
      setError(`No user ${uid} found on project ${getProjectId()}`)
    );
  }
  dispatch(setUser(user, uid));

  // Attach realtime updates
  if (get(user, 'uiSettings.selectedSetting')) {
    const db = firebase
      .firestore()
      .collection('settings')
      .doc(get(user, 'uiSettings.selectedSetting'));

    dispatch(onSnapshot(db, setSetting));
    dispatch(onSnapshot(db.collection('zendeskMacros'), setZendeskMacros));
    dispatch(onSnapshot(db.collection('zendeskAuthors'), setZendeskAuthors));
  } else {
    dispatch(
      setError(
        `No selected setting for the account ${uid} on project ${getProjectId()}`
      )
    );
  }
};

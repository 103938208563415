import {get, last, pick, identity} from 'lodash';
import {runFilters} from './cloud-functions';
import {isAutoReply, getMappedIntentByMacro} from './macros';

export const analyseResponse = stringifiedResponse => async (
  dispatch,
  getState
) => {
  const response = JSON.parse(stringifiedResponse);
  const {
    id,
    zendeskTicketId,
    creation,
    responseDate,
    language = '',
    intents,
    responseMacroId,
    responseWasAutoReplied,
    zendeskTicket,
  } = response;

  // Get suggested macro only based on macro intent mapping
  const suggestedMacrosOnIntentMap = dispatch(
    getIntentMappingSuggestions(response.intents)
  );

  // if filters set on macro, run filters
  const filtersResults = await dispatch(
    getFilteredSuggestions(suggestedMacrosOnIntentMap, response)
  );

  const suggestedMacros =
    (filtersResults || []).length > 0
      ? filtersResults.filter(({suggest}) => suggest).map(({id}) => id)
      : suggestedMacrosOnIntentMap;

  const autoReply =
    suggestedMacros.length === 1
      ? dispatch(isAutoReply(suggestedMacros[0]))
      : false;

  const usedMacroMapped = Boolean(
    dispatch(getMappedIntentByMacro(responseMacroId)).length
  );

  const usedMacroMappedOnIntent = dispatch(
    getMappedIntentByMacro(responseMacroId)
  ).includes(intents[0]);

  const firstCommentHasAttachment =
    get(last(zendeskTicket.comments), 'attachments', []).length > 0;

  const macroUsed =
    responseWasAutoReplied && !responseMacroId
      ? 'auto-replied (bug)'
      : responseMacroId;

  const isSuggestionAccurate =
    responseWasAutoReplied && !responseMacroId
      ? 'maybe (bug)'
      : suggestedMacros.map(String).includes(responseMacroId);

  const suggestionType =
    suggestedMacros.length === 0
      ? 'none'
      : suggestedMacros.length === 1
        ? 'single'
        : 'multi';

  return {
    response: {
      id,
      zendeskTicketId,
      creation,
      responseDate,
      intents,
      language,
      macroUsed,
      responseWasAutoReplied,
    },
    autoReply,
    filtersResults,
    suggestedMacros,
    usedMacroMapped,
    usedMacroMappedOnIntent,
    firstCommentHasAttachment,
    isSuggestionAccurate,
    suggestionType,
  };
};

const getIntentMappingSuggestions = (intents = []) => (dispatch, getState) => {
  const primaryIntent = intents[0] || 'no_intent';
  const macroIntentPairs = get(
    getState(),
    'firestore.setting.macroIntentPairs',
    []
  );

  const suggestedMacroIds = macroIntentPairs
    .filter(m => [primaryIntent, 'all'].includes(m.intent)) // Add 'all' intent
    .map(m => m.macro)
    .filter((v, i, a) => a.indexOf(v) === i); // This remove duplicate macro id in array

  return suggestedMacroIds;
};

const getFilteredSuggestions = (suggestedMacros = [], response = {}) => async (
  dispatch,
  getState
) => {
  const filters = get(getState(), 'firestore.setting.macroFilters', []);
  const macroFilters = filters.filter(({id}) => suggestedMacros.includes(id));
  const {intents, zendeskTicket, context: ticketContext} = response;

  // if filters set on macro, run filters
  if (macroFilters.length === 0) {
    return [];
  }

  const res = await dispatch(
    runFilters({
      macroFilters,
      suggestedMacros,
      intents,
      ticketContext,
      zendeskTicket,
    })
  );

  return get(res, 'results', []);
};

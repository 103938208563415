import PropTypes from 'prop-types';
import {createSelector} from 'reselect';
import {map, orderBy} from 'lodash';

export const SAMPLE_SCHEMA = PropTypes.shape({
  creation: PropTypes.string.isRequired,
  dataset: PropTypes.shape({
    size: PropTypes.number.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
  }),
});

const getSamples = state => {
  const {samples} = state.firestore;
  if (!samples) {
    return undefined;
  }

  const sortedSamples = orderBy(
    map(samples, sample => sample),
    ['creation'],
    ['desc']
  );

  return sortedSamples;
};

export const getFormatedtSamples = createSelector(
  [getSamples],
  samples => samples
);

import React from 'react';
import {connect} from 'react-redux';
import {orderBy, get} from 'lodash';
import uuidv1 from 'uuid';

import {
  getFormatedEvalJobsComparison,
  getFormatedIntentsComparison,
  EVAL_JOBS_SCHEMA,
  INTENTS_SCHEMA,
} from '../../selectors/eval-jobs';
import {percent} from '../../lib/utils';
import CValue from '../compared-value';

const Intents = ({evalJobs, intents}) => {
  const currentIntents = orderBy(intents.current, ['coverage'], ['desc']);
  const previousIntents = intents.previous;

  return (
    <table className="table mb-4 light-table">
      <thead>
        <tr>
          <th>Intent</th>
          <th>Distribution</th>
          <th>Coverage</th>
          <th>Precision</th>
          <th>Training dataset</th>
          <th>Evaluation dataset</th>
        </tr>
      </thead>
      <tbody>
        {currentIntents.map(intent => (
          <tr key={uuidv1()}>
            <td>{intent.key}</td>
            <td>
              {percent(
                (intent.datasetSize + intent.evalsetSize) /
                  (evalJobs.current.datasetSize + evalJobs.current.evalsetSize)
              )}
              %
            </td>
            <td>
              <CValue
                value={intent.coverage}
                prevValue={get(previousIntents, [intent, 'coverage'])}
              >
                %
              </CValue>
            </td>
            <td>
              <CValue
                value={intent.precision}
                prevValue={get(previousIntents, [intent, 'precision'])}
              >
                %
              </CValue>
            </td>
            <td>
              <CValue
                value={intent.datasetSize}
                prevValue={get(previousIntents, [intent, 'datasetSize'])}
              >
                {' '}
                message
                {intent.datasetSize > 1 && 's'}
              </CValue>
            </td>
            <td>
              <CValue
                value={intent.evalsetSize}
                prevValue={get(previousIntents, [intent, 'evalsetSize'])}
              >
                {' '}
                message
                {intent.evalsetSize > 1 && 's'}
              </CValue>
            </td>
          </tr>
        ))}

        {currentIntents.length === 0 && (
          <tr>
            <td colSpan="100%">No intents deployed</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default connect(state => ({
  settingsId: state.firestore.setting.id,
  evalJobs: getFormatedEvalJobsComparison(state),
  intents: getFormatedIntentsComparison(state),
}))(Intents);

Intents.propTypes = {
  evalJobs: EVAL_JOBS_SCHEMA,
  intents: INTENTS_SCHEMA,
};

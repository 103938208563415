import firebase from 'firebase/app';

export const getJwt = settingId => {
  const fn = firebase.functions().httpsCallable('getJwt');
  return fn({settingId});
};

export const graphql = (operationName, variables) => {
  // variables
  // SettingsId is ALWAYS required
  // - settingsId: '9sChbE0yUT6cys8NqhW4',
  // Variables for SearchTickets operation
  // - intent: 'status_check',
  // - first: 1,
  // - after: 'NTAyNTA5',
  // createdBefore: '2018-07-01T00:00:00+02:00',
  // createdAfter: '2018-06-01T00:00:00+02:00',
  // Variables for GetTicket operation
  // - zendeskTicketId: '12342'
  const query = `
    query SearchTicketsByResponse($settingsId: String!, $intent: String, $first: Int, $after: String, $respondedBefore: String, $respondedAfter: String!) {
      settings(settingsId: $settingsId) {
        ticketConnectionByResponded(intent: $intent, first: $first, after: $after, respondedBefore: $respondedBefore, respondedAfter: $respondedAfter) {
          totalCount
          edges {
            node {
              id
              zendeskTicketId
              context
              zendeskTicket
              intents
              language
              creation
              responseDate
              responseMacroId
              responseWasAutoReplied
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  query SearchEvalJobByConfig($settingsId: String!, $config: String!, $version: String!) {
    settings(settingsId: $settingsId) {
      evalJobConnectionByConfig(config: $config, version: $version) {
        edges {
          node {
            id
            endDate
            success
            version
            counts
            recall
            coverage
            precision
            training
          }
        }
      }
    }
  }
`;
  const fn = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('graphql');
  return fn({
    query,
    variables,
    operationName,
  });
};
